import React from "react";
import Project, { ProjectProps } from "./project";

export type ProjectsProps = {
    projects: ProjectProps[];
}

export default class Projects extends React.Component<ProjectsProps> {

    render() {
        return (
            <section id="projects" className="topic-container">
                <h1>Projects of Interest</h1>
                { this.props.projects.map((project) => {
                    return (<Project key={project.id} {...project}/>)
                })}
                <hr/>
            </section>
        )
    }
}