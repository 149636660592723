import React from "react";
import ReactMarkdown from "react-markdown";

export type MarkdownProps = {
    src:      string | undefined;
    children: string | undefined;
}

export function MarkdownImage(props : any) {
    let src : string = props.src;
    let isVideo = false;
    if (src.endsWith('mp4')) {
        isVideo = true;
    }
    return (<div className="markdown-image-container">
        { isVideo && <video autoPlay={true} loop={true} muted={true}><source src={src} type="video/mp4"></source></video> }
        { !isVideo && <img {...props} alt={props.alt} /> }
    </div>)
}

export function MarkdownPTag(props : any) : any {
    let children = props.children;
    let hasImage = !!children.find((child : any) => typeof child === "object" && child.key && (child.key.match(/image/g) || child.key.match(/video/g)));
    return hasImage ? children : <p>{children}</p>
};

export type AsyncMarkdownLoaderProps = {
    src: string | undefined;
    children: string | undefined
}

export type AsyncMarkdownLoaderState = {
    src: string | undefined;
    children: string | undefined;
}

export default class RenderMarkdown extends React.Component<AsyncMarkdownLoaderProps, AsyncMarkdownLoaderState> {
    static defaultProps = {
        src: undefined,
        children: undefined
    }

    constructor(props : any) {
        super(props);
        this.state = { src: this.props.src, children: this.props.children }
       

        if (this.props.children !== undefined) {
            let regex = /<\[([\w\-/.]+)\]>/gmi;
            let match = regex.exec(this.props.children);

            if (match !== null) {
                let newState = {
                    ...this.state,
                    children: undefined,
                    src: match[1]
                };
                this.state = newState;
            }
        }

        this.loadSrc = this.loadSrc.bind(this);
    }

    async loadSrc(){
        let path = process.env.PUBLIC_URL + '/' + this.state.src as string;
        console.log(`Loading Markdown from "${path}"`)

        let payload = await fetch(path);
        let data = await payload.text();
        
        this.setState({
            ...this.state,
            src: undefined,
            children: data
        })
    }

    componentDidMount() {
        if (this.state.src !== undefined && this.state.children === undefined) {
            this.loadSrc();
        }
    }

    render() {
        if (this.state.children !== undefined) {
            return (
                <ReactMarkdown children={this.state.children as string} linkTarget="_blank" components={{
                    img: MarkdownImage,
                    p: MarkdownPTag
                }}/>
            )
        }
        else {
            return (
                <div className="loading-spinner" />
            )
        }
    }
}