import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export type FooterProps = {
    twitter_handle: string,
    gitlab_handle: string,
    github_handle: string,
    linkedin_handle: string,
    itch_handle: string | undefined;
}

export default class Footer extends React.Component<FooterProps> {

    render() {
        return (
            <section id="footer" className="footer">
                <Container>
                    <Row>
                        <Col xs={12}>
                            This site was built by hand with love using React, Bootstrap and Typescript 💜
                        </Col>
                    </Row>
                    <Row className="footer-links justify-content-center">
                        <Col xs={6} sm={4} md={2}>
                            <a href={`https://twitter.com/${this.props.twitter_handle}`} target="_blank" rel="noreferrer"><i className="fa fa-twitter" /> Twitter</a>
                        </Col>
                        <Col xs={6} sm={4} md={2}>
                            <a href={`https://gitlab.com/${this.props.gitlab_handle}`} target="_blank" rel="noreferrer"><i className="fa fa-gitlab" /> GitLab</a>
                        </Col>
                        <Col xs={6} sm={4} md={2}>                            
                            <a href={`https://github.com/${this.props.github_handle}`} target="_blank" rel="noreferrer"><i className="fa fa-github" /> GitHub</a>
                        </Col>
                        <Col xs={6} sm={4} md={2}>
                            <a href={`https://linkedin.com/in/${this.props.linkedin_handle}`} target="_blank" rel="noreferrer"><i className="fa fa-linkedin" /> LinkedIn</a>
                        </Col>
                        <Col xs={6} sm={4} md={2}>
                            <a href={`https://${this.props.itch_handle}.itch.io/`} target="_blank" rel="noreferrer"><i className="fa-brands fa-itch-io" /> itch.io</a>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <em>&copy; {new Date().getFullYear()} Sage Matthews</em>
                        </Col>    
                    </Row>
                </Container>
            </section>
        )
    }
}