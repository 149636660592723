import React from "react";
import { Table } from "react-bootstrap";
import RenderMarkdown from "../utils/render-markdown";

export type Skill = {
    name: string;
    rank: string;
    years: string;
    note: string | undefined;
}

export type SkillsProps = {
    skills: Skill[]; 
}

export default class Skills extends React.Component<SkillsProps> {

    render() {
        return (
            <section id="skills" className="w-100 topic-container">
                <h1>Skills</h1>
                <div className="d-sm-none d-md-block">
                    <Table striped responsive={true} bordered variant="dark" size="sm" className="w-md-100 w-lg-75 m-auto">
                        <thead>
                            <td>Skill</td>
                            <td>Years of Experience</td>
                            <td>Experience Level</td>
                            <td>Notes</td>
                        </thead>
                        <tbody>                        
                            { this.props.skills.map((skill) => {
                                return (
                                    <tr id={`skill-${encodeURIComponent(skill.name)}`}>
                                        <td>
                                            <em className="lang-item">{skill.name}</em>
                                        </td>
                                        <td>
                                            {skill.years}
                                        </td>
                                        <td>
                                            {skill.rank}
                                        </td>
                                        <td>
                                            <RenderMarkdown children={skill.note} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div> 
                <div className="d-sm-block d-md-none">                    
                    {
                        this.props.skills.map((skill, ix) => {
                            return (
                                <div key={ix} className="skill-container">
                                    <b id={`skill-${encodeURIComponent(skill.name)}`} className="lang-item">{skill.name}</b>
                                    <em>{skill.years} years ({skill.rank})</em>
                                    <RenderMarkdown children={skill.note} />                
                                </div>
                            );
                        })
                    }
                </div>
                <em>There's likely others I'm not including here, but I have a wide range of skills from a variety of languages and developer tasks, so it can be difficult to keep track of them all!</em>
            </section>
        )
    }
}