import React from "react";

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Ratio } from "react-bootstrap";
import RenderMarkdown from "../utils/render-markdown";

export type AboutProps = {
    profile_pic : string;
    description : string[];
    pronouns    : string;
}

export type AboutState = {

}

export default class About extends React.Component<AboutProps, AboutState> {
    state : AboutState = {};

    render() {

        return (
            <section id="about" className="about topic-container">
                <Container >
                    <h1>About me:</h1>
                    <em>Sage L. Matthews ({this.props.pronouns})</em>
                    <Row className="mt-2 justify-content-sm-center">
                        <Col xs={12} md={3}>
                            <div style={{ maxHeight: "256px", maxWidth: "256px", margin: "auto" }}>
                                <Ratio aspectRatio="1x1">
                                    <img className="profile-pic" src={this.props.profile_pic} alt="Sage"/>
                                </Ratio> 
                            </div>
                        </Col>
                        <Col xs={12} md={9}>
                            <div className="about-me">
                                { 
                                    this.props.description.map((line, ix) => 
                                        <RenderMarkdown key={ix} children={line} />
                                    )
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}