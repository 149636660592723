import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Experience, { ExperienceProps } from "./experience";

export type ExperiencesProps = {
    experiences: ExperienceProps[];
};

export default class Experiences extends React.Component<ExperiencesProps> {

    render() {

        return (
            <section id="experience" className="topic-container">
                <h1><u>Experience and Education</u></h1>
                <Container>
                    <Row className="justify-content-center">                        
                        {
                            this.props.experiences.map((t, index, array) => {
                                let experience = this.props.experiences[array.length - 1 - index];
                                return (<Col xs={12} md={6}><Experience key={experience.id} {...experience} /></Col>)
                            })
                        }
                    </Row>
                </Container>
            </section>
        )
    }
}