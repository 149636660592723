import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { format } from "date-fns";
import RenderMarkdown from "../utils/render-markdown";

export type ExperienceProps = {
    // A unique ID for the entry
    id:          string;
    // Name of company or school
    name:        string;
    // Link to the company or school
    url:         string | undefined;
    // Role at the institution
    title:       string;
    // URL for logo for the company or institution
    logo:        string | undefined;
    // A short description of the work done
    shortDesc:   string;
    // Year and month started
    date_start:  Date;
    // Year and month ended
    date_end:    Date | undefined;
    // List of points of interest
    description_lines: string[];
    // Skills learned/applied
    skills:      string[];
}

export default class Experience extends React.Component<ExperienceProps> {

    render() {
        return (
            <Container id={`experience-${this.props.id}`} className="experience-container">
                <Row>
                    <Col xs={12}>
                        <h3>
                            { this.props.url !== undefined &&
                                <a href={this.props.url}>{this.props.name}</a>
                            }
                            { this.props.url === undefined &&
                                <span>{this.props.name}</span>
                            }
                        </h3>
                        <h6><RenderMarkdown children={this.props.shortDesc} /></h6>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-justify">
                        <em>{this.props.title}</em>
                        &nbsp;
                        &#40;
                        <em>{format(this.props.date_start, 'MMM Y')} - { this.props.date_end === undefined ? "current" : format(this.props.date_end, 'MMM Y')}</em>
                        &#41;
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col xs={12} className="experience-skill">
                        { this.props.skills.map(skill => {
                            return (<a key={skill} className="lang-item experience-skill" href={`#skill-${encodeURIComponent(skill)}`}>{skill}</a>)
                        })}
                    </Col>
                </Row>
                <Row className="pt-4">
                    <Col xs={12}>
                        <ul style={{ textAlign: "left" }}>
                        {
                            this.props.description_lines.map((item, ix) => {
                                return (<li className="experience-line" key={ix}><RenderMarkdown children={item} /></li>)
                            })
                        }
                        </ul>
                    </Col>
                </Row>
            </Container>
        )
    }
}