import React from "react";
import { Carousel } from "react-bootstrap";
import RenderMarkdown from "../utils/render-markdown";

export enum ImageCarouselImageType {
    Image = "image",
    Video = "video"
};

export class ImageCarouselImage {
    src: string | undefined;
    caption : string | undefined;
    alt: string | undefined;
    type: ImageCarouselImageType | undefined;
}

export type ImageCarouselProps = {
    images: ImageCarouselImage[];
    autoscroll: boolean;
    starting_index: number;
    controlled: boolean;
}

export type ImageCarouselState = {
    image_index: number;
}

class ImageCarousel extends React.Component<ImageCarouselProps, ImageCarouselState> {
    state: ImageCarouselState;

    public static defaultProps : Partial<ImageCarouselProps> = {
        autoscroll: true,
        starting_index: 0,
        controlled: true
    }

    constructor(props : ImageCarouselProps) {
        super(props);
        this.state = {
            image_index: 0
        };
        
        this.handleSelection = this.handleSelection.bind(this);
    }

    handleSelection(selectedIndex : number, e : Record<string, unknown> | null) {
        this.setState({
            ...this.state,
            image_index: selectedIndex % this.props.images.length
        });
    }

    render() {
        return (
            <Carousel activeIndex={this.state.image_index} onSelect={this.props.controlled ? this.handleSelection : undefined} interval={10000}>
                {this.props.images.map((image, ix) => {
                    return (
                        <Carousel.Item key={ix}>
                            <div className="carousel-image-container">
                                { (image.type || ImageCarouselImageType.Image) === ImageCarouselImageType.Image &&
                                    <img
                                        className="carousel-image"
                                        src={image.src}
                                        alt={image.alt} 
                                    />
                                }
                                { (image.type || ImageCarouselImageType.Image) === ImageCarouselImageType.Video &&
                                    <video
                                        className="carousel-image"
                                        autoPlay={true}
                                        loop={true}
                                        muted={true}
                                    >
                                        <source src={image.src} type="video/mp4" />
                                    </video>
                                }
                            </div> 
                            <Carousel.Caption>
                                <RenderMarkdown children={image.caption} />
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        )
    }
}

export default ImageCarousel;