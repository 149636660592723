import React from "react";
import { Row, Container, Accordion } from "react-bootstrap";
import { LinkData } from "../types/link";
import RenderMarkdown from "../utils/render-markdown";
import ImageCarousel, { ImageCarouselImage } from "./image_carousel";

export type ProjectProps = {
    id:          string;
    title:       string;
    shortDesc:   string | undefined;
    description: string[] | undefined;
    images:      ImageCarouselImage[] | undefined;
    languages:   string[] | undefined;
    links:       LinkData[] | undefined;
};

export type ProjectState = {
    image_id: number;
    description_expanded: boolean;
};

export default class Project extends React.Component<ProjectProps, ProjectState> {
    state : ProjectState = { 
        image_id: 0,
        description_expanded: false
    }

    public static defaultProps = {
        shortDesc:   undefined,
        description: undefined,
        images:      undefined,
        languages:   undefined
    };

    constructor(props : ProjectProps) {
        super(props);

        this.onAccordionSelect = this.onAccordionSelect.bind(this);
    }

    onAccordionSelect(eventKey: string | string[] | null | undefined, event: any) {
        this.setState({
            ...this.state,
            description_expanded: eventKey !== null
        });
    }

    render() {
        return (
            <Container id={ `project-${this.props.id}`} className="project topic-container">
                <Row>
                    <h3>{this.props.title}</h3>
                    <em>{this.props.shortDesc}</em>
                    <div className="lang-container">
                    {this.props.languages?.map((lang) => {
                        return (<a key={lang} className="lang-item" href={`#skill-${encodeURIComponent(lang)}`}>{lang}</a>);
                    })}
                    </div>
                    <Accordion aria-expanded={this.state.description_expanded} flush={true} onSelect={this.onAccordionSelect}>
                        <Accordion.Header className="accordion-custom" style={{ backgroundColor: "#282c34"}}>{this.state.description_expanded ? "See Less" : "See More"}</Accordion.Header>
                        <Accordion.Body>
                            <div className="project-body">   
                                <div className="project-description">                           
                                    {
                                        // Each line of our description will get it's own paragraph tag
                                        this.props.description?.map((line, ix) => {
                                            return (<RenderMarkdown key={ix} children={line} />)
                                        })
                                    }
                                    {
                                        // We want to render all of the links associated with our project
                                        this.props.links?.map((link) => {
                                            return (
                                                <div className="project-link">
                                                    <a href={link.href} target="_blank" rel="noreferrer">
                                                        { link.icon !== undefined &&  (<span><i className={`fa ${link.icon as string}`}></i> </span>) }
                                                        {link.text}
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }
                                </div>  
                                {
                                    this.props.images !== undefined &&
                                        <ImageCarousel images={this.props.images as ImageCarouselImage[]}/>
                                }
                            </div>
                        </Accordion.Body>
                    </Accordion>
                </Row>
            </Container>
        )
    }
}